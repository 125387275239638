export const createTableQuantity = (statisticsDto, props) => {
  return Object.values(
    statisticsDto.reduce((group, item) => {
      group[item[props]] = group[item[props]] || {
        firstColumn: item[props],
        newOwns: 0,
        savingOnes: 0,
        givenOnes: 0,
        approvingOnes: 0,
        doneOnes: 0,
        nrmCalling: 0,
        nrmOrStopRejected: 0
      };

      let counting = item?.statisticsTimelapsesDto?.mapStatisticsToTimelapse;
      let groupItem = group[item[props]];

      if (counting) {
        Object.values(counting).forEach(count => {
          groupItem["newOwns"] += Number(count["newOnes"]);
          groupItem["savingOnes"] += Number(count["savingOnes"]);
          groupItem["givenOnes"] += Number(count["givenOnes"]);
          groupItem["approvingOnes"] += Number(count["approvingOnes"]);
          groupItem["doneOnes"] += Number(count["doneOnes"]);
          groupItem["nrmCalling"] += Number(count["nrmCalling"]);
          groupItem["nrmOrStopRejected"] += Number(count["nrmOrStopRejected"]);
        });
      }

      return group;
    }, {})
  );
};

export default {
  createTableQuantity
};
