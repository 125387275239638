import axios from "axios";
import router from "@/router";

function getDealerQuantityStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}api/statistics/cps/day/count?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}api/statistics/cps/month/count?${queryUrl}`
    );
  }
}
function getDealerQuantitySumStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}api/statistics/cps/day/sum?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}api/statistics/cps/month/sum?${queryUrl}`
    );
  }
}
function getEmployeeQuantityStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}esa/statistics/cps/day/count?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}esa/statistics/cps/month/count?${queryUrl}`
    );
  }
}
function getEmployeeQuantitySumStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}esa/statistics/cps/day/sum?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}esa/statistics/cps/month/sum?${queryUrl}`
    );
  }
}
function getPBEmployeeQuantityStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}esa/statistics/cps/pb/day/count?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}esa/statistics/cps/pb/month/count?${queryUrl}`
    );
  }
}
function getPBEmployeeSumStats(range, queryUrl) {
  if (range === "date") {
    return axios.get(
      `${router.options.base}esa/statistics/cps/pb/day/sum?${queryUrl}`
    );
  } else {
    return axios.get(
      `${router.options.base}esa/statistics/cps/pb/month/sum?${queryUrl}`
    );
  }
}

export {
  getDealerQuantityStats,
  getDealerQuantitySumStats,
  getEmployeeQuantityStats,
  getEmployeeQuantitySumStats,
  getPBEmployeeQuantityStats,
  getPBEmployeeSumStats
};
