<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $t("statistic.title.application") }}
        </h2>
      </v-col>
    </v-row>
    <FilterDash :isStatuses="true" @download="downloadStat" />
    <v-row class="row-border-bottom">
      <v-col v-if="!emptyCompanyChart">
        <highcharts v-if="showDash" :options="chartOptions" />
        <div v-else class="progress-wrap">
          <v-progress-circular
            :size="50"
            color="privat"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col
        v-else
        class="d-flex flex-column justify-center align-center my-7 no-data"
      >
        <v-icon x-large>mdi-alert-circle-outline</v-icon>
        <span class="text-subtitle-1 pt-1 font-weight-bold">
          {{ $i18n.t("statistic.emptyData") }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $t(`${tableQuantityTitle}`) }} (по заявкам)
        </h2>
      </v-col>
    </v-row>
    <FilterDash :isSalon="true" @download="downloadStatQuantity" />
    <v-row class="row-border-bottom pb-2">
      <v-col>
        <v-data-table
          :headers="tableQuantityHeader"
          :items="tableQuantityData"
          :loading="loadingQuantity"
          :mobile-breakpoint="600"
          item-key="name"
          hide-default-footer
          locale="uk"
          class="elevation-1"
        >
          <v-progress-linear
            v-show="loadingQuantity"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div class="d-flex flex-column justify-center align-center">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-column justify-center align-center my-7">
              <v-icon x-large>mdi-alert-circle-outline</v-icon>
              <span class="text-subtitle-1 pt-1 font-weight-bold">
                {{ $i18n.t("statistic.emptyData") }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $t(`${tableSumTitle}`) }} (по сумам)
        </h2>
      </v-col>
    </v-row>
    <FilterDash :isSalon="true" @download="downloadStatSum" />
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableSumHeader"
          :items="tableSumData"
          :loading="loadingSum"
          :mobile-breakpoint="600"
          item-key="name"
          hide-default-footer
          locale="uk"
          class="elevation-1"
        >
          <v-progress-linear
            v-show="loadingSum"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div class="d-flex flex-column justify-center align-center">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-column justify-center align-center my-7">
              <v-icon x-large>mdi-alert-circle-outline</v-icon>
              <span class="text-subtitle-1 pt-1 font-weight-bold">
                {{ $i18n.t("statistic.emptyData") }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </div>
</template>

<script>
import FilterDash from "@/components/Statistic/FilterDash";
import {
  getDealerQuantityStats,
  getDealerQuantitySumStats
} from "@/services/statistics.service";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import checkFormDate from "@/utils/checkFormDate";
import { createTableQuantity } from "@/utils/createStatsTables";

export default {
  name: "DealerStat",
  components: {
    FilterDash
  },
  mixins: [showSnack],
  data: () => ({
    loadingSum: true,
    loadingQuantity: true,
    form: null,
    formStatsSum: null,
    formStatsQuantity: null,
    xAxis: [],
    series: null,
    showDash: false,
    tableSumData: [],
    tableSumHeader: [],
    tableQuantityData: [],
    tableQuantityHeader: [],
    emptyCompanyChart: false
  }),
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "xy"
        },
        title: {
          text: ``
        },
        xAxis: {
          categories: this.xAxis,
          labels: {
            enabled: true,
            rotation: 300
          }
        },
        yAxis: {
          title: {
            text: ""
          },
          min: 0,
          max: this.yAxisMax,
          allowDecimals: false
        },
        tooltip: {
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            stacking: "normal"
            // dataLabels: {
            //   enabled: true
            // }
          }
        },
        series: this.series
      };
    },
    tableSumTitle() {
      if (this.formStatsSum && this.formStatsSum.salonSelected !== "all") {
        return "statistic.title.bestEmployees";
      }

      return "statistic.title.bestSalons";
    },
    tableQuantityTitle() {
      if (
        this.formStatsQuantity &&
        this.formStatsQuantity.salonSelected !== "all"
      ) {
        return "statistic.title.bestEmployees";
      }

      return "statistic.title.bestSalons";
    }
  },
  methods: {
    downloadStat(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.emptyCompanyChart = false;
      this.showDash = false;
      this.form = form;
      getDealerQuantityStats(this.form.rangeSelected, queryUrl)
        .then(res => {
          this.makeDash(res);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");
          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.emptyCompanyChart = true;
        })
        .finally(() => {
          this.showDash = true;
        });
    },
    downloadStatSum(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.loadingSum = true;
      this.formStatsSum = form;
      getDealerQuantitySumStats(this.formStatsSum.rangeSelected, queryUrl)
        .then(res => {
          this.makeTableSum(res);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");
          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
        })
        .finally(() => {
          this.loadingSum = false;
        });
    },
    downloadStatQuantity(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.loadingQuantity = true;
      this.formStatsQuantity = form;
      getDealerQuantityStats(this.formStatsQuantity.rangeSelected, queryUrl)
        .then(res => {
          this.makeTableQuantity(res);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");
          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.loadingQuantity = false;
        })
        .finally(() => {
          this.loadingQuantity = false;
        });
    },
    setQueryFromAndTo(form, props, type) {
      // реверс дати, якщо першою була вибрана більш пізня дата
      let from = form[`${type}`][0];
      let to = form[`${type}`][1];

      if (props === "from") {
        return from > to ? to : from;
      }
      if (props === "to") {
        return from > to ? from : to;
      }
    },
    createQueryURL(form) {
      const query = {};

      if (form["rangeSelected"] === "date") {
        query["from"] = this.setQueryFromAndTo(form, "from", "dates");
        query["to"] = this.setQueryFromAndTo(form, "to", "dates");
      } else {
        query["from"] = this.setQueryFromAndTo(form, "from", "months");
        query["to"] = this.setQueryFromAndTo(form, "to", "months");
      }
      if (form["salonSelected"] !== "all") {
        query["storeIdSet"] = form["salonSelected"];
      }

      return new URLSearchParams(query).toString();
    },
    makeDash({ data }) {
      let mapStatisticsToTimelapse =
        data?.statisticsTimelapsesDto?.mapStatisticsToTimelapse;

      if (mapStatisticsToTimelapse) {
        let isDataEmpty = 0;

        this.xAxis = Object.keys(mapStatisticsToTimelapse);

        let grouped = Object.values(mapStatisticsToTimelapse).reduce(
          (group, item) => {
            if (
              this.form.contextSelected === "newOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["newOnes"] = group["newOnes"] || {
                name: this.$i18n.t("statistic.statuses.new"),
                type: "column",
                data: [],
                zIndex: 0,
                color: "#96d6de"
              };
              group["newOnes"]["data"].push(Number(item["newOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "savingOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["savingOnes"] = group["savingOnes"] || {
                name: this.$i18n.t("statistic.statuses.saving"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#0a2ccc"
              };
              group["savingOnes"]["data"].push(Number(item["savingOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "givenOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["givenOnes"] = group["givenOnes"] || {
                name: this.$i18n.t("statistic.statuses.given"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#bd380b"
              };
              group["givenOnes"]["data"].push(Number(item["givenOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "approvingOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["approvingOnes"] = group["approvingOnes"] || {
                name: this.$i18n.t("statistic.statuses.approving"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#FFB300"
              };
              group["approvingOnes"]["data"].push(
                Number(item["approvingOnes"])
              );
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "doneOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["doneOnes"] = group["doneOnes"] || {
                name: this.$i18n.t("statistic.statuses.done"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#83BF31"
              };
              group["doneOnes"]["data"].push(Number(item["doneOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }

            return group;
          },
          {}
        );

        this.yAxisMax = isDataEmpty ? null : 5;
        this.series = Object.values(grouped);
        this.showDash = true;
      }
      this.emptyCompanyChart = !this.showDash;
    },
    makeTableSum({ data }) {
      let statisticsStoreDto = data?.statisticsOrgDtoList
        .map(item => {
          return item.statisticsStoreDto.flat();
        })
        .flat();

      if (this.formStatsSum.salonSelected !== "all") {
        statisticsStoreDto = statisticsStoreDto
          .filter(item => {
            return item.storeId === this.formStatsSum.salonSelected;
          })
          .map(item => {
            return item.statisticsEmployeeDto.flat();
          })
          .flat();
      }
      if (statisticsStoreDto.length === 0) {
        this.tableSumData = [];
        return;
      }
      if (this.formStatsSum.salonSelected === "all") {
        this.tableSumData = this.createSumTable(statisticsStoreDto, "name");
      } else {
        this.tableSumData = this.createSumTable(statisticsStoreDto, "fio");
      }
      this.tableSumHeader = this.createHeader();
    },
    makeTableQuantity({ data }) {
      let statisticsStoreDto = data?.statisticsOrgDtoList
        .map(item => {
          return item.statisticsStoreDto.flat();
        })
        .flat();

      if (this.formStatsQuantity.salonSelected !== "all") {
        statisticsStoreDto = statisticsStoreDto
          .filter(item => {
            return item.storeId === this.formStatsQuantity.salonSelected;
          })
          .map(item => {
            return item.statisticsEmployeeDto.flat();
          })
          .flat();
      }

      if (statisticsStoreDto.length === 0) {
        this.tableQuantityData = [];
        return;
      }

      if (this.formStatsQuantity.salonSelected === "all") {
        this.tableQuantityData = createTableQuantity(
          statisticsStoreDto,
          "name"
        );
      } else {
        this.tableQuantityData = createTableQuantity(statisticsStoreDto, "fio");
      }

      this.tableQuantityHeader = this.createHeader(true);
    },
    createHeader(quantity) {
      const sumHeaders = [
        {
          text: this.$i18n.t("statistic.table.totalAmountWithSavedCP"),
          value: "savingOnes",
          class: "savingOnes"
        },
        {
          text: this.$i18n.t("statistic.table.totalAmountFundingWithSavedCP"),
          value: "creditSavingOnes",
          class: "creditSavingOnes"
        },
        {
          text: this.$i18n.t("statistic.table.totalAmountCPSubmittedToBank"),
          value: "givenOnes",
          class: "givenOnes"
        },
        {
          text: this.$i18n.t(
            "statistic.table.totalAmountFundingCPSubmittedToBank"
          ),
          value: "creditGivenOnes",
          class: "creditGivenOnes"
        }
      ];
      const quantityHeaders = [
        {
          text: this.$i18n.t("statistic.table.newOwns"),
          value: "newOwns",
          class: "new"
        },
        {
          text: this.$i18n.t("statistic.table.savingOnes"),
          value: "savingOnes",
          class: "saving"
        },
        {
          text: this.$i18n.t("statistic.table.givenOnes"),
          value: "givenOnes",
          class: "given"
        },
        {
          text: this.$i18n.t("statistic.table.approvingOnes"),
          value: "approvingOnes",
          class: "approving"
        },
        {
          text: this.$i18n.t("statistic.table.doneOnes"),
          value: "doneOnes",
          class: "done"
        }
      ];
      let headers = [
        {
          text: this.$i18n.t("statistic.table.salon"),
          value: "firstColumn",
          class: "firstColumn"
        }
      ];

      if (
        this.formStatsSum.salonSelected !== "all" ||
        this.formStatsQuantity.salonSelected !== "all"
      ) {
        headers = [
          {
            text: this.$i18n.t("statistic.table.employee"),
            value: "firstColumn",
            class: "firstColumn"
          }
        ];
      }

      quantity ? headers.push(...quantityHeaders) : headers.push(...sumHeaders);

      return headers;
    },
    createSumTable(statisticsStoreDto, props) {
      return Object.values(
        statisticsStoreDto.reduce((group, item) => {
          group[item[props]] = group[item[props]] || {
            firstColumn: item[props],
            savingOnes: 0,
            creditSavingOnes: 0,
            givenOnes: 0,
            creditGivenOnes: 0
          };

          let counting =
            item?.statisticsTimelapsesDto?.mapStatisticsToTimelapse;
          let groupItem = group[item[props]];

          if (counting) {
            let sOnes = 0;
            let cSOnes = 0;
            let gOnes = 0;
            let cGOnes = 0;

            Object.values(counting).forEach(count => {
              sOnes += Number(count["savingOnes"]);
              cSOnes += Number(count["creditSavingOnes"]);
              gOnes += Number(count["givenOnes"]);
              cGOnes += Number(count["creditGivenOnes"]);
            });

            groupItem["savingOnes"] = +sOnes.toFixed(2);
            groupItem["creditSavingOnes"] = +cSOnes.toFixed(2);
            groupItem["givenOnes"] = +gOnes.toFixed(2);
            groupItem["creditGivenOnes"] = +cGOnes.toFixed(2);
          }

          return group;
        }, {})
      );
    }
  },
  beforeDestroy() {
    clearTimeout(this.localTimeout);
  }
};
</script>

<style scoped>
.row-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.progress-wrap {
  position: relative;
  min-height: 100px;
}
</style>
