<template>
  <v-row justify="end" id="stat-filter">
    <v-col class="d-flex" xl="2" lg="2" md="4" sm="6" cols="12">
      <v-select
        v-model="form.companiesOrEmployees"
        :items="companiesOrEmployeesOptions"
        :label="$i18n.t('statistic.companyOrEmployees')"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col
      v-if="form.companiesOrEmployees === 'companies'"
      class="d-flex"
      xl="2"
      lg="2"
      md="4"
      sm="6"
      cols="12"
    >
      <v-select
        v-model="form.company"
        :items="companies"
        :label="$i18n.t('statistic.company')"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col
      v-if="form.companiesOrEmployees === 'companies'"
      class="d-flex"
      xl="2"
      lg="2"
      md="4"
      sm="6"
      cols="12"
    >
      <v-select
        v-model="form.salonSelected"
        :items="salonOptions"
        :label="$i18n.t('statistic.salon')"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col
      v-if="isStatuses"
      class="d-flex"
      xl="2"
      lg="2"
      md="4"
      sm="6"
      cols="12"
    >
      <v-select
        v-model="form.contextSelected"
        :items="contextOptions"
        :label="$i18n.t('statistic.inTheContext')"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col class="d-flex" xl="2" lg="2" md="4" sm="6" cols="12">
      <v-select
        v-model="form.rangeSelected"
        :items="rangeOptions"
        :label="$i18n.t('statistic.range.range')"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col
      v-if="form.rangeSelected === 'date'"
      key="picker-date"
      class="d-flex"
      xl="2"
      lg="2"
      md="4"
      sm="6"
      cols="12"
    >
      <v-menu
        v-model="showDatePiker"
        ref="menu"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="computedDateFormattedMoment"
            :label="$i18n.t('statistic.period')"
            append-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          id="bidPeriod"
          v-model="form.dates"
          :locale="langDatePiker"
          :first-day-of-week="1"
          :max="maxDate"
          type="date"
          no-title
          scrollable
          range
          @change="showDatePiker = false"
          color="privat"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      v-else
      key="picker-month"
      class="d-flex"
      xl="2"
      lg="2"
      md="4"
      sm="6"
      cols="12"
    >
      <v-menu
        v-model="showDatePiker"
        ref="menu"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="computedDateFormattedMomentMonth"
            :label="$i18n.t('statistic.period')"
            append-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.months"
          :locale="langDatePiker"
          :first-day-of-week="1"
          :max="maxDate"
          type="month"
          no-title
          scrollable
          range
          @change="showDatePiker = false"
          color="privat"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="d-flex" xl="1" lg="1" md="4" sm="6" cols="12">
      <v-btn
        icon
        elevation="1"
        class="privat--bg w-100"
        rounded
        tile
        @click="$emit('download', form)"
      >
        <v-icon :class="{ 'mr-2': btnWidthFull }">mdi-download</v-icon>
        <span v-if="btnWidthFull">{{ $t("action.download") }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CONST from "@/utils/constants";
export default {
  name: "FilterDash",
  props: {
    isSalon: {
      type: Boolean,
      default: false
    },
    isStatuses: {
      type: Boolean,
      default: false
    },
    salons: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    showDatePiker: false,
    langDatePiker: null,
    maxDate: null,
    form: {
      dates: [],
      months: [],
      contextSelected: "all",
      salonSelected: "all",
      rangeSelected: "date",
      companiesOrEmployees: "employees",
      company: "all",
      employee: "all"
    }
  }),
  computed: {
    ...mapGetters([
      "getOfferOptions",
      "getUser",
      "getStatus",
      "getPointOfEntry",
      "getOrdersAndSettingsOptions"
    ]),
    btnWidthFull() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    computedDateFormattedMoment() {
      let dates = this.form.dates;

      dates = dates
        .filter(item => item)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        });

      return dates
        .map(item => moment(item, "YYYY-MM-DD").format("DD.MM.YYYY"))
        .join(" ~ ");
    },
    computedDateFormattedMomentMonth() {
      let months = this.form.months;

      months = months
        .filter(item => item)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        });

      return months
        .map(item => moment(item, "YYYY-MM").format("MM.YYYY"))
        .join(" ~ ");
    },
    contextOptions() {
      return [
        {
          value: "all",
          text: this.$i18n.t("statistic.statuses.all")
        },
        // {
        //   value: "newOnes",
        //   text: this.$i18n.t("statistic.statuses.new")
        // },
        {
          value: "savingOnes",
          text: this.$i18n.t("statistic.statuses.saving")
        },
        {
          value: "givenOnes",
          text: this.$i18n.t("statistic.statuses.given")
        },
        {
          value: "approvingOnes",
          text: this.$i18n.t("statistic.statuses.approving")
        },
        {
          value: "doneOnes",
          text: this.$i18n.t("statistic.statuses.done")
        }
      ];
    },
    rangeOptions() {
      return [
        {
          value: "date",
          text: this.$i18n.t("statistic.range.days")
        },
        {
          value: "month",
          text: this.$i18n.t("statistic.range.month")
        }
      ];
    },
    companiesOrEmployeesOptions() {
      return [
        {
          value: "employees",
          text: this.$i18n.t("statistic.employees")
        },
        {
          value: "companies",
          text: this.$i18n.t("statistic.companies")
        }
      ];
    },
    salonOptions() {
      let salons = [];
      if (
        this.form.companiesOrEmployees === "companies" &&
        this.form.company !== "all"
      ) {
        const company = this.getOrdersAndSettingsOptions?.ogrDataFilterCps.find(
          item => item.orgId === this.form.company
        );
        if (company?.storeFilterSet) {
          salons = company.storeFilterSet.map(item => ({
            value: item["storeId"],
            text: item["storeName"]
          }));
        }
      }
      salons.unshift({
        value: "all",
        text: this.$i18n.t("statistic.allSalon")
      });
      return salons;
    },
    companies() {
      let companies =
        this.getOrdersAndSettingsOptions?.ogrDataFilterCps.map(item => ({
          value: item["orgId"],
          text: item["orgName"]
        })) || [];
      companies.unshift({
        value: "all",
        text: this.$i18n.t("statistic.allCompanies")
      });
      return companies;
    }
  },
  watch: {
    "form.companiesOrEmployees"() {
      if (this.form.companiesOrEmployees === "employees") {
        this.form.company = "all";
        this.form.contextSelected = "all";
        this.form.alonSelected = "all";
      }
      this.form.rangeSelected = "date";
    },
    salonOptions: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.form.salonSelected = newVal[0].value;
        }
      }
    },
    "form.rangeSelected"() {
      this.setMaxDate();
      if (this.form.rangeSelected === "date") {
        this.createRange();
      } else {
        this.createRangeMonths();
      }
    }
  },
  created() {
    this.setMaxDate();
    this.createRange();
    this.createRangeMonths();
  },
  mounted() {
    this.langDatePiker = CONST.LOCALE[this.$i18n.locale].toLowerCase();
    this.$emit("download", this.form);
  },
  methods: {
    nextMonth(d) {
      let fm = moment(d).add(1, "M");
      let fmEnd = moment(fm).endOf("month");

      return d.date() !== fm.date() && fm.isSame(fmEnd.format("YYYY-MM-DD"))
        ? fm.add(1, "d").format("YYYY-MM-DD")
        : fm.format("YYYY-MM-DD");
    },
    setMaxDate() {
      if (this.form.rangeSelected === "date") {
        this.maxDate = moment().format("YYYY-MM-DD");
      } else {
        // this.maxDate = this.nextMonth(moment());
        this.maxDate = moment().format("YYYY-MM-DD");
      }
    },
    createRange() {
      this.form.dates = [
        moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ];
    },
    createRangeMonths() {
      this.form.months = [
        moment()
          .startOf("month")
          .format("YYYY-MM"),
        moment()
          .startOf("month")
          .format("YYYY-MM")
      ];
    }
  }
};
</script>

<style scoped>
.privat--bg {
  background-color: var(--color-privat);
  color: #fff !important;
}
.w-100 {
  width: 100%;
}
</style>

<style>
#stat-filter .v-text-field__details {
  display: none;
}
</style>
