<template>
  <v-container id="statistic">
    <EmloyeeStat v-if="getPointOfEntry" />
    <DealerStat v-else />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DealerStat from "@/components/Statistic/DealerStat";
import EmloyeeStat from "@/components/Statistic/EmloyeeStat";

export default {
  name: "Statistic",
  components: {
    DealerStat,
    EmloyeeStat
  },
  computed: {
    ...mapGetters(["getPointOfEntry"])
  }
};
</script>

<style scoped lang="scss">
#statistic {
  @media (min-width: 1264px) {
    max-width: 95%;
  }
  @media (min-width: 960px) {
    max-width: 95%;
  }
}
.statistic-chart {
  width: 100%;
  height: 500px;
}
</style>
